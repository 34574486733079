.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .notes p {
  color: #666;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.Notes form {
  padding-bottom: 15px;
}

.Notes form textarea {
  height: 300px;
  font-size: 24px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.NewNote form {
  padding-bottom: 15px;
}

.NewNote form textarea {
  height: 300px;
  font-size: 24px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.BillingForm .card-field {
    margin-bottom: 15px;
    background-color: white;
    padding: 11px 16px;
    border-radius: 6px;
    border: 1px solid #CCC;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    line-height: 1.3333333;
  }
  
  .BillingForm .card-field.StripeElement--focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    border-color: #66AFE9;
  }
@media all and (min-width: 480px) {
    .Settings {
      padding: 60px 0;
    }
  
    .Settings form {
      margin: 0 auto;
      max-width: 480px;
    }
  }
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

